var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('sb-page-header',{scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('h1',[_vm._v("Scholen")])]},proxy:true},{key:"right",fn:function(){return [(
          ['ADMIN', 'PARTNER_MANAGER', 'SCHOOL_DISTRICT_MANAGER'].includes(
            _vm.$user.role
          )
        )?_c('router-link',{attrs:{"to":{
          name: 'ResourceManagementOrganisationAdd',
          params: { organisationType: 'school', manageMethod: 'add' },
        }}},[_c('i-button',{attrs:{"type":"primary","ghost":""}},[_vm._v(" School toevoegen "),_c('icon',{attrs:{"type":"md-add"}})],1)],1):_vm._e()]},proxy:true}])}),_c('sb-schools-table',{attrs:{"organisation-id":_vm.$user.context.resourceType === 'ADMIN'
        ? null
        : _vm.organisationId || _vm.resourceId}}),_c('sb-spacer',{attrs:{"height":"200"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }