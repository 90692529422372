<template>
  <div>
    <sb-page-header>
      <template v-slot:left>
        <h1>Instellingen</h1>
      </template>
      <template v-slot:right> </template>
    </sb-page-header>

    <template>
      <i-form ref="school" :rules="validationRules" :label-width="160">
        <sb-divider title="School seats" />
        <sb-change-seats
          v-if="
            (data && !data.parentOrganisation) ||
            (data.parentOrganisation && !data.parentOrganisation.invoiceSelf)
          "
          :organisation-id="data.id"
          :initial-seats="data.seats"
        />

        <p
          v-if="data.parentOrganisation && data.parentOrganisation.invoiceSelf"
        >
          De seats zijn gedefinieerd in het bovenliggende schooldistrict
          (<router-link
            :to="{
              name: 'ResourceManagementSchooldistrictsSchooldistrict',
              params: { organisationId: data.parentOrganisation.id },
            }"
          >
            {{ data.parentOrganisation.name }} </router-link
          >).
        </p>

        <sb-spacer height="20" />
        <sb-divider title="School status" />

        <sb-set-closing-date
          :organisation-id="data.id"
          :initial-date="data.closingDate"
          :data="data"
          @change="$emit('refetch')"
        />

        <sb-divider title="School deactiveren" />

        <form-item label="Status aanpassen:">
          <sb-activate-organisation
            :id="data.id"
            label="School"
            :active="data.active"
          />
        </form-item>

        <template v-if="this.$user.context.isAdmin">
          <sb-divider title="Bovenschoolse Organisatie" />
          <sb-spacer height="20" />
          <sb-add-or-remove-from-resource
            :original="selectedSchoolDistrict"
            :options="schoolDistricts"
            :child-id="data.id"
            migration-selector="schoolId"
            label="Schooldistrict"
            slug="schoolDistrict"
          />

          <sb-spacer height="400" />
        </template>
      </i-form>
    </template>

    <sb-spacer height="20" />
  </div>
</template>

<script>
import SbDivider from '@/components/SbDivider';
import SbAddOrRemoveFromResource from '@/components/SbAddOrRemoveFromResource.vue';
import SbChangeSeats from '@/components/SbChangeSeats.vue';
import SbActivateOrganisation from '@/components/SbActivateOrganisation.vue';
import SbPageHeader from '@/components/SbPageHeader';
import SbSetClosingDate from '@/components/SbSetClosingDate';

export default {
  components: {
    SbDivider,
    SbAddOrRemoveFromResource,
    SbActivateOrganisation,
    SbChangeSeats,
    SbPageHeader,
    SbSetClosingDate,
  },
  props: {
    data: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      selectedSchoolDistrict: null,
      selectedSchool: null,
      organisationSeats: null,
      validationRules: {},
    };
  },
  computed: {
    schools() {
      if (!this.organisations) return [];
      return this.organisations.filter(
        (organisation) => organisation.organisationType === 'SCHOOL',
      );
    },
    schoolDistricts() {
      if (!this.organisations) return [];
      return this.organisations.filter(
        (organisation) => organisation.organisationType === 'SCHOOL_DISTRICT',
      );
    },
  },
  watch: {
    data() {
      this.populateData();
    },
  },
  mounted() {
    this.populateData();
  },
  methods: {
    populateData() {
      this.selectedSchoolDistrict = this.data?.parentOrganisation?.id;
    },
  },
};
</script>

<style></style>
