<template>
  <div>
    <!-- <Breadcrumb>
      <BreadcrumbItem>Scholen</BreadcrumbItem>
    </Breadcrumb> -->

    <sb-page-header>
      <template v-slot:left>
        <h1>Scholen</h1>
      </template>
      <template v-slot:right>
        <router-link
          v-if="
            ['ADMIN', 'PARTNER_MANAGER', 'SCHOOL_DISTRICT_MANAGER'].includes(
              $user.role,
            )
          "
          :to="{
            name: 'ResourceManagementOrganisationAdd',
            params: { organisationType: 'school', manageMethod: 'add' },
          }"
        >
          <i-button type="primary" ghost>
            School toevoegen

            <icon type="md-add" />
          </i-button>
        </router-link>
      </template>
    </sb-page-header>

    <sb-schools-table
      :organisation-id="
        $user.context.resourceType === 'ADMIN'
          ? null
          : organisationId || resourceId
      "
    />

    <sb-spacer height="200" />
  </div>
</template>

<script>
import SbPageHeader from '@/components/SbPageHeader';

import SbSchoolsTable from '@/components/SbSchoolsTable';

export default {
  name: 'OnlineStudents',
  components: {
    SbPageHeader,
    SbSchoolsTable,
  },
  props: {
    organisationId: {
      type: String,
      default: null,
    },
    courseGroupId: {
      type: String,
      default: null,
    },
    resourceId: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss"></style>
