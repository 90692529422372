<template>
  <div>
    <form-item :label="label" prop="schooldistrict">
      <i-select
        v-model="selectedOption"
        style="max-width: 300px"
        :placeholder="`Kies een ${label}`"
        :disabled="disabled"
      >
        <i-option v-for="option in options" :key="option.id" :value="option.id">
          {{ option.name }}
        </i-option>
      </i-select>
    </form-item>

    <form-item
      v-if="seatsRequired && originalOption !== selectedOption"
      label="Licenties"
      prop="seats"
    >
      <input-number
        v-model="seats"
        placeholder="Licenties"
        :min="1"
        :step="1"
        style="min-width: 300px"
      ></input-number>
    </form-item>

    <form-item>
      <sb-spacer height="10" />
      <i-button
        v-if="selectedOption && originalOption !== selectedOption"
        type="primary"
        @click="confirmAndUpdateOption('change')"
      >
        Aanpassen
      </i-button>
      <i-button
        v-if="originalOption === selectedOption && selectedOption !== null"
        :loading="loading"
        type="error"
        @click="confirmAndUpdateOption('delete')"
      >
        Ontkoppelen
      </i-button>
    </form-item>
  </div>
</template>

<script>
import MigrateToParentOrganisation from '@/graphql/mutations/MigrateToParentOrganisation.gql';
import DisconnectFromParentOrganisation from '@/graphql/mutations/DisconnectFromParentOrganisation.gql';
import gql from 'graphql-tag';

export default {
  props: {
    original: { type: String, default: null },
    childId: { type: String, default: null },
    label: { type: String, required: true },
    migrationSelector: { type: String, required: true },
    slug: { type: String, required: true },
    disabled: { type: Boolean, default: false },
    options: { type: Array, default: () => [] },
  },

  data() {
    return {
      selectedOption: null,
      originalOption: null,
      loading: false,
      seats: 1,
    };
  },

  watch: {
    original(newVal) {
      this.originalOption = newVal;
      this.selectedOption = newVal;
    },
  },

  mounted() {
    this.originalOption = this.original;
  },

  methods: {
    confirmAndUpdateOption(type) {
      this.$Modal.confirm({
        title: 'Let op!',
        content: {
          change: `Weet je zeker dat je deze school onder een ${this.label.toLowerCase()} wilt hangen?`,
          delete: 'Weet je zeker dat je deze school wilt ontkoppelen?',
        }[type],

        closable: true,
        onOk: () => {
          setTimeout(() => {
            this.changeSchoolOption(type === 'change');
          }, 300);
        },
      });
    },

    async changeSchoolOption(isEditMode) {
      this.loading = true;

      try {
        const { data } = await this.$apollo.mutate({
          mutation: isEditMode
            ? MigrateToParentOrganisation
            : DisconnectFromParentOrganisation,
          variables: {
            input: {
              subjectId: this.childId,
              ...(isEditMode
                ? {
                    parentId: this.selectedOption,
                    seats: this.seatsRequired ? this.seats : undefined,
                  }
                : { seats: 1 }),
              // ?edit=== 'delete' ? {parentId: this.selectedOption } : {},
            },
          },
        });

        if (!isEditMode) {
          this.selectedOption = null;
          this.originalOption = null;
        } else {
          this.originalOption = this.selectedOption;
        }

        if (data) {
          setTimeout(() => {
            this.$Message.success(
              isEditMode
                ? 'De school is succesvol aangepast!'
                : 'De school is succesvol ontkoppeld',
            );
          }, 300);
        }
      } catch (e) {
        //   this.$Modal.error({
        //   title: 'Error',
        //   content
        // });
      }
      this.loading = false;
    },
  },

  apollo: {
    seatsRequired: {
      skip() {
        return !this.selectedOption;
      },

      query() {
        return gql`query SbAddOrRemoveFromResource_GetOrganisationById {
          getOrganisationById(id: "${this.selectedOption}") {
            id
            ... on SchoolDistrict {
              invoiceSelf
            }
          }
        }`;
      },

      update(data) {
        return Boolean(!data?.getOrganisationById?.invoiceSelf);
      },
    },
  },
};
</script>

<style></style>
